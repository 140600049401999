<template>
    <div>
      <!-- Start Header Area -->
      <Header>
        <img slot="logo" :src="logo" />
      </Header>
      <!-- End Header Area -->
  
      <!-- Start Breadcrumb Area  -->
      <div class="rn-page-title-area pt--120 pb--190 bg_image bg_image--1">
        <v-container>
          <v-row>
            <v-col cols="12">
              <div class="text-center rn-page-title pt--100">
                <h2 class="heading-title theme-gradient">
                  Major Project: Learning Experience Final Design
                </h2>
                <p>Module 7 Assignment</p>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <!-- End Breadcrumb Area  -->
  
      <!-- Start Portfolio Details Area  -->
      <div class="rn-portfolio-details ptb--120 bg_color--1">
        <v-container>
          <v-row>
            <v-col>
              <div class="portfolio-details">
                <div class="row row--35 mb--50">
                  <div class="col-lg-12">
                    <div class="inner">
                      <!-- Part 1: Learning Experience Description -->
                      <div class="section-title">
                        <p><a class="btn-default btn-border" href="/documents/Learning Experience Design Guide 2.docx">Download Document</a></p>
                        <h2 class="heading-title">Part 1: Learning Experience Description</h2>
                        <div class="description mt--30" style="text-align: justify !important">
                          
                          <h4>Learning Experience Title</h4>
                          <p>Full-Stack Web Development: Preparing Computer Science Students for Modern Web Applications</p>

                          <h4>Abstract</h4>
                          <p>This 15-week learning experience is designed for undergraduate Computer Science students, focusing on full-stack web development. The course provides comprehensive training on both frontend and backend technologies, covering modern web development frameworks, databases, and deployment techniques. Students will engage in hands-on projects, assessments, and lab work designed to simulate real-world development environments. By the end of the course, students will have built and deployed a fully functioning web application, gaining skills that are immediately applicable in professional settings.</p>

                          <h4>Learner Audience / Primary Users</h4>
                          <p>The primary audience is undergraduate Computer Science students, particularly those interested in web development and software engineering. These learners will typically have some foundational programming knowledge, although experience with web technologies is not required.</p>

                          <h4>Educational Use</h4>
                          <p>This learning experience is designed as part of a Computer Science curriculum, specifically tailored for courses on web development. It will also serve as a professional preparation module for students planning careers in web development or full-stack engineering roles.</p>

                          <h4>Language</h4>
                          <p>English</p>

                          <h4>Material Type</h4>
                          <p>
                            <ul>
                              <li>Videos</li>
                              <li>Hands-on Lab Activities</li>
                              <li>Projects</li>
                              <li>Assessments</li>
                              <li>Course-related Reading Materials</li>
                            </ul>
                          </p>

                          <h4>Keywords</h4>
                          <p>Full-stack development, Web development, Frontend, Backend, JavaScript, Node.js, Databases, Deployment, APIs, Real-world applications</p>

                          <h4>Time Required for Learning Experience</h4>
                          <p>This is a 15-week course, with approximately 5-6 hours per week of videos, labs, project work, and assessments, totaling around 75-90 hours of learning.</p>

                          <h4>Targeted Skills</h4>
                          <p>
                            <ul>
                              <li>Building dynamic, responsive web applications using modern frameworks</li>
                              <li>Developing and integrating APIs for communication between frontend and backend</li>
                              <li>Working with databases (SQL and NoSQL)</li>
                              <li>Version control and collaborative development using Git and GitHub</li>
                              <li>Deploying applications on cloud platforms</li>
                            </ul>
                          </p>

                          <h4>Learning Objectives</h4>
                          <p>By the end of this learning experience, learners will be able to:
                            <ol>
                              <li>Build a full-stack web application from scratch using modern web technologies.</li>
                              <li>Integrate frontend and backend components using APIs.</li>
                              <li>Set up, manage, and query both SQL and NoSQL databases.</li>
                              <li>Implement user authentication and secure data handling.</li>
                              <li>Deploy web applications on cloud platforms (e.g., AWS, Heroku).</li>
                              <li>Collaborate effectively using Git and GitHub for version control.</li>
                            </ol>
                          </p>

                          <h4>Prior Knowledge</h4>
                          <p>Learners should have foundational programming skills in a language such as JavaScript or Python. Basic understanding of HTML and CSS will be helpful but is not required.</p>

                          <h4>Required Resources</h4>
                          <p>
                            <ul>
                              <li>A computer with access to a code editor (VSCode preferred)</li>
                              <li>Internet access for collaborative tools and cloud services</li>
                              <li>Git and GitHub accounts for version control</li>
                              <li>Accounts for cloud hosting services (e.g., AWS, Heroku, DigitalOcean)</li>
                              <li>Access to educational platforms for video lessons (if applicable)</li>
                            </ul>
                          </p>

                          <h4>Rationale</h4>
                          <p>The rationale for this learning experience is rooted in the increasing demand for full-stack web developers in the tech industry. This course bridges the gap between theory and practice by offering a hands-on, project-based approach to full-stack development. Students will gain a deep understanding of both frontend and backend technologies, enabling them to build dynamic, scalable, and secure web applications.</p>
                          <p>This course aligns with my teaching philosophy, which emphasizes hands-on, real-world experience. By integrating full-stack development with best practices in version control, security, and cloud deployment, students will acquire skills that are directly applicable to industry standards. The 15-week structure allows for an in-depth exploration of each component, from building interfaces to deploying fully functional applications.</p>
                        </div>
                      </div>

                      <!-- Part 2: Learning Experience -->
                      <div class="section-title">
                        <h2 class="heading-title">Part 2: Learning Experience</h2>
                        <div class="description mt--30" style="text-align: justify !important">
                          
                          <h4>Instructional Strategies and Activities</h4>
                          
                          <h5>Warm-Up</h5>
                          <p>Time: 10 minutes</p>
                          <p>Activity: Begin each session with a quick review of the previous week’s topics. This includes questions to assess retention and a brief discussion to stimulate interest in the current session’s content.</p>

                          <h5>Introduction</h5>
                          <p>Time: 15 minutes</p>
                          <p>Activity: Introduce key concepts for the session, connecting their importance to real-world applications in full-stack development. Engage learners by discussing how today’s skills can directly impact web application functionality and user experience.</p>

                          <h5>Presentation / Modeling / Demonstration</h5>
                          <p>Time: 30 minutes</p>
                          <p>Activity: Demonstrate coding techniques and explain complex concepts using code samples and visual aids. Break down each component of full-stack development (frontend, backend, databases) and provide live coding sessions to model best practices.</p>

                          <h5>Guided Practice</h5>
                          <p>Time: 30 minutes</p>
                          <p>Activity: Learners will implement small parts of a web application under guidance. Instructor will provide feedback and support as they practice coding and integrating front-end and back-end components.</p>

                          <h5>Assessment</h5>
                          <p>Time: 15 minutes</p>
                          <p>Activity: Conduct a mini-assessment to verify understanding, such as a short quiz on key terms or a small coding exercise. Provide corrective feedback and further explanations if needed.</p>

                          <h5>Application</h5>
                          <p>Time: 20 minutes</p>
                          <p>Activity: Allow learners to apply the day's skills in a real-world context, such as modifying a feature in their project or integrating an API into their app. This reinforces learning and facilitates knowledge transfer to new situations.</p>

                          <h4>Key Terms and Concepts</h4>
                          <p>API, Backend, Cloud Deployment, Database, Frontend, Full-Stack Development, Git, GitHub, JavaScript, Node.js, SQL, NoSQL, Version Control</p>
                        </div>
                      </div>

                      <!-- Part 3: Supplementary Resources & References -->
                      <div class="section-title">
                        <h2 class="heading-title">Part 3: Supplementary Resources & References</h2>
                        <div class="description mt--30" style="text-align: justify !important">
                          
                          <h4>Supplementary Resources</h4>
                          <p>
                            <ul>
                              <li><a href="https://www.w3schools.com">W3Schools</a> – Introductory tutorials for frontend and backend development</li>
                              <li><a href="https://www.javascript.info">JavaScript.info</a> – Detailed JavaScript resources</li>
                              <li><a href="https://developer.mozilla.org">MDN Web Docs</a> – Comprehensive web development documentation</li>
                              <li><a href="https://www.heroku.com">Heroku</a> – Cloud platform for deploying applications</li>
                              <li><a href="https://git-scm.com">Git</a> – Official Git documentation</li>
                            </ul>
                          </p>
                          <h4>References</h4>
<ul>
  <li>
    MDN Web Docs. (n.d.). <em>Introduction to web APIs</em>. Mozilla. Retrieved from 
    <a href="https://developer.mozilla.org/en-US/docs/Learn/JavaScript/Client-side_web_APIs/Introduction" target="_blank">
      https://developer.mozilla.org/en-US/docs/Learn/JavaScript/Client-side_web_APIs/Introduction
    </a>
  </li>
  <li>
    W3Schools. (n.d.). <em>HTML, CSS, JavaScript, SQL, Python, PHP, Bootstrap, Java, and XML tutorials</em>. 
    Retrieved from 
    <a href="https://www.w3schools.com" target="_blank">https://www.w3schools.com</a>
  </li>
  <li>
    Heroku. (n.d.). <em>Deploying applications with Heroku</em>. Heroku Documentation. Retrieved from 
    <a href="https://devcenter.heroku.com/articles/deployment" target="_blank">
      https://devcenter.heroku.com/articles/deployment
    </a>
  </li>
</ul>


                          <h4>Attribution Statements</h4>
                          <p>Original content contributed by Cody Squadroni for the Full-Stack Web Development Learning Experience.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <!-- End Portfolio Details Area  -->
  
      <Footer />
    </div>
  </template>
  
  <script>
  import Header from "../../components/header/HeaderThree";
  import Footer from "../../components/footer/FooterTwo";
  import feather from "feather-icons";
  
  export default {
    components: {
      Header,
      Footer,
    },
    data() {
      return {
        logo: require("../../assets/images/logo/logo.png"),
      };
    },
    methods: {
      iconSvg(icon) {
        return feather.icons[icon].toSvg();
      },
    },
  };
  </script>
